"use client";

import { ErrorMessage } from "@hookform/error-message";
import clsx from "clsx";
import { usePathname, useRouter } from "next/navigation";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { InputField } from "../../form/fields/InputField/InputField";
import { CheckboxInput } from "../../form/input/CheckboxInput/CheckboxInput";
import { AddressSearchBox } from "../AddressSearchBox/AddressSearchBox";
import { Button } from "../Button/Button";
import { MaterialIcon } from "../MaterialIcon/MaterialIcon";
import styles from "./ProductLookup.module.scss";

interface ProductLookupProps {
	searchParams?: Record<string, string>;
}
export const ProductLookup = ({ searchParams }: ProductLookupProps) => {
	const router = useRouter();
	const pathname = usePathname();

	const formMethods = useForm({
		defaultValues: {
			address: searchParams?.address || "",
			promo: searchParams?.promo || "",
			hasPromoCode: searchParams?.promo ? true : false,
			dpid: searchParams?.dpid || "",
		},
		reValidateMode: "onChange",
		shouldFocusError: false,
	});

	const [isBusiness, setIsBusiness] = useState(false);
	const [addressObj, setAddressObj] = useState({});
	const [address, setAddress] = useState(searchParams?.address || "");

	const {
		handleSubmit,
		formState: { errors },
		register,
		resetField,
		watch,
		setValue,
		clearErrors,
	} = formMethods;

	const dpid = watch("dpid");
	const promoCode = watch("promo");
	const isPromoChecked = watch("hasPromoCode");

	const [formProcessing, setFormProcessing] = useState(false);

	const onSubmit: Parameters<typeof handleSubmit>[0] = async data => {
		console.log("onSubmit", data);

		setFormProcessing(true);
		const queryParams = new URLSearchParams({
			dpid,
			address,
			addressDetail: JSON.stringify(addressObj),
		});

		if (promoCode) {
			queryParams.append("promo", promoCode);
		}

		if (isBusiness) {
			queryParams.append("isBusiness", "true");
		}

		// this is needed in order to refresh the results when the user clicks the button again
		queryParams.append("uuid", crypto.randomUUID());

		if (pathname === "apply-online") {
			router.push(`?${queryParams.toString()}#product-result`);
		} else {
			router.push(`/apply-online?${queryParams.toString()}#product-result`);
		}

		const productContainer = document.getElementById("product-result");
		if (productContainer) {
			productContainer.scrollIntoView({ behavior: "smooth" });
			setFormProcessing(false);
		}
	};

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles["product-lookup"]}
			>
				<AddressSearchBox
					defaultAddress={address}
					onClearClicked={() => {
						setAddressObj({});
						setValue("dpid", "");
					}}
					onAddressSelected={(data: any) => {
						setValue("dpid", data.dpid);
						clearErrors("address");
						setAddress(data.addressText);
						setAddressObj(data.address);
						setIsBusiness(data.isBusiness);
					}}
					onInputChange={() => {
						setValue("dpid", "");
					}}
				/>
				<div className={styles["promo"]}>
					<CheckboxInput
						className={styles["promo__checkbox"]}
						label="I have a promo code"
						{...register("hasPromoCode", {
							onChange: () => {
								setValue("promo", "");
							},
						})}
					/>
					<div
						className={clsx(
							styles["promo__input-wrapper"],
							!isPromoChecked && styles["promo__input-wrapper--hidden"],
						)}
						aria-hidden={!isPromoChecked}
					>
						<span className={styles["promo__icon"]}>
							<MaterialIcon name="shoppingmode" />
						</span>
						<InputField
							inputProps={{ tabIndex: isPromoChecked ? 0 : -1 }}
							className={styles["promo__field"]}
							label="Promo code"
							placeholder="Enter code"
							name="promo"
							registerOptions={{
								validate: (value, fieldValues) =>
									fieldValues.hasPromoCode && !value
										? "Please enter a promo code or deselect the checkbox to continue"
										: true,
								disabled: !isPromoChecked,
							}}
							hideError
							hideLabel
						/>
						{promoCode && (
							<button
								className={styles["promo__clear"]}
								aria-label="Clear promo code"
								onClick={() => resetField("promo")}
								type="button"
							>
								<MaterialIcon name="close" />
							</button>
						)}
					</div>
				</div>

				{Object.entries(errors).length > 0 && (
					<div className={styles["errors"]}>
						<span className={styles["errors__icon"]}>
							<MaterialIcon name="warning" />
						</span>
						<ul>
							{errors.address && (
								<li>
									<ErrorMessage
										errors={errors}
										name="address"
									/>
								</li>
							)}
							{errors.promo && (
								<li>
									<ErrorMessage
										errors={errors}
										name="promo"
									/>{" "}
								</li>
							)}
						</ul>
					</div>
				)}
				<Button
					type="submit"
					showArrow
					disabled={formProcessing}
					label={formProcessing ? "Loading plans" : "See Plan"}
				/>
			</form>
		</FormProvider>
	);
};
