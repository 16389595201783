import { client } from "@/sanity/client";
import { getFileAsset, SanityFileSource } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";
import { ImageFormat, SanityImageSource } from "@sanity/image-url/lib/types/types";

const builder = imageUrlBuilder(client);

export const urlFor = (source: SanityImageSource) => {
	return builder.image(source);
};

export const getFileData = (source: string) => {
	const imageData = client.getDocument(source);
	return imageData;
};

export const getFile = (src: SanityFileSource) => {
	return getFileAsset(src, {
		projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
		dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
	});
};

export const emptyPixel =
	"data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

/**
 * Transform an object of parameters into a weserv URL - for dummy content
 */
export function srcParamsDummyData(
	src: string,
	params: Record<string, string | number | null | undefined>,
): string {
	// Create a query params string from an arbitrary object
	const paramsString = Object.entries(params)
		// Ensure we have a truthy value
		.filter(arr => arr[1])
		// Create the &key=value string
		.map(arr => "&" + arr[0] + "=" + arr[1])
		// Join all strings
		.join("");
	return "https://wsrv.nl/?url=" + encodeURIComponent(src) + paramsString;
}

/**
 * Transform an object of parameters into a Sanity URL - for real content
 */
export function srcParamsSanity(
	/** Assume a storyblok asset URL */
	src: string,
	params: Record<string, string | number | null | undefined>,
): string {
	const url = new URL(src);

	if (params.fit === "contain") {
		url.searchParams.set("fit", "clip");
	} else {
		url.searchParams.set("fit", "crop");
	}

	/** Add size */
	if (params.dpr) {
		url.searchParams.set("dpr", String(params.dpr));
	}
	url.searchParams.set("w", String(params.width));
	url.searchParams.set("h", String(params.height));

	if (params.output) {
		url.searchParams.set("fm", String(params.output));
	}

	if (params.quality) {
		url.searchParams.set("q", String(params.quality));
	}

	return url.toString();
}

/**
 * Transform an object of parameters into a Sanity URL - for real content
 */
export function pictureSanityImageBuilder(
	/** Assume a storyblok asset URL */
	image: SanityImageSource,
	params: Record<string, string | number | null | undefined>,
): string {
	let url = urlFor(image);

	if (params.fit === "contain") {
		url = url.fit("clip");
	} else {
		url = url.fit("crop");
	}

	/** Add size */
	if (params.dpr) {
		url = url.dpr(Number(params.dpr));
	}

	url = url.width(Number(params.width));
	url = url.height(Number(params.height));

	if (params.output) {
		url = url.format(String(params.output) as ImageFormat);
	}

	if (params.quality) {
		url.quality(Number(params.quality));
	}

	return url.toString();
}

export function ogImageDimensions(src: string | undefined | null) {
	if (!src) return "";

	return srcParamsSanity(src, {
		width: 1200,
		height: 630,
	});
}
